.div {
  background-image: url('../Images/Backgrounds/blue.png');

  background-size: cover;
  margin         : 0%;
  padding        : 3%;
  width          : 100%;
  height         : 550px;
  margin-bottom  : 5%;
}

.div1 {

  width      : 35%;
  float      : left;
  margin     : 10px;
  margin-left: 3%;
}

.div2 {

  display   : block;
  width     : 60%;
  height    : 100%;
  float     : left;
  max-height: fit-content;
}

.image {
  display: inline-block;
  width  : 100%;
  height : 100%;
}

.buttons {
  text-decoration : none;
  border-radius   : 5px;
  background-color: rgb(184, 22, 22);
  border          : solid black 1px;
  padding         : 5px;
  font-weight     : bold;
  color           : rgb(247, 244, 244);
}

Link:hover {
  background-color: rgb(126, 7, 7);
  text-decoration : none;
  color           : white;
}

.heading {
  font-size: max(3.5vw, 45px);
}

.text {
  text-align: justify;
  font-size : max(1.4vw, 16px);
}

@media only screen and (max-width: 850px) {
  .div {
    margin-bottom   : 20px;
    background-image: url('../Images/Backgrounds/blue.png');
    height          : 100%;
  }

  .div1 {
    float       : none;
    width       : 90%;
    margin-right: 20px;
  }

  .div2 {
    float : none;
    width : 100%;
    height: 70%;
  }

  .image {
    display: inline-block;
    width  : 100%;
    height : 70%;
  }

  .heading {
    text-align  : center;
    padding-left: 10px;
  }
}