@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+HK&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Noto+Sans+HK&display=swap");

.rowNineCarouselWrapper {
  background-image: url("https://drive.google.com/uc?id=1fLWLZn7phXdIVHXRV2tce6N91SH73J-6");
}

.rowNineCarouselRoot > div {
  display: flex;
  justify-content: center;
}

.rowNineCarouselDiv img {
  padding: 25px 80px;
}
@media screen and (min-width: 1590px) {}
@media screen and (min-width: 1450px) and (max-width: 1589px) {}
@media screen and (min-width: 1300px) and (max-width: 1449px) {}
@media screen and (min-width: 1260px) and (max-width: 1299px) {}
@media screen and (min-width: 1126px) and (max-width: 1259px) {}
@media screen and (min-width: 1051px) and (max-width: 1125px) {}
@media screen and (min-width: 1000px) and (max-width: 1050px) {}
@media screen and (min-width: 1000px) and (max-width: 1125px) {}
@media screen and (min-width: 870px) and (max-width: 999px) {}
@media screen and (min-width: 769px) and (max-width: 999px) {
  .rowNineCarouselDiv img {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (width: 768px) {
  .rowNineCarouselDiv img {
    padding-left: 36px;
    padding-right: 36px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .rowNineCarouselWrapper {
    margin: 0 2rem;
  }
  .rowNineCarouselDiv img {
    padding: 15px 15px;
    height:400px;
  }
}
@media screen and (min-width: 700px) and (max-width: 767px) {}
@media screen and (min-width: 640px) and (max-width: 700px) {}
@media screen and (max-width: 575px) {
  .rowNineCarouselWrapper {
    margin: 0 2rem;
  }

  .rowNineCarouselDiv img {
    padding: 12px;
    height:300px;
  }
}
@media screen and (max-width: 444px) {
  .rowNineCarouselDiv img {
    padding: 8px 8px;
    height:250px;
    padding:0;
  }
}
@media screen and (max-width: 360px) {
  .rowNineCarouselWrapper {
    margin: 0 1rem;
  }

  .rowNineCarouselDiv img {
    padding: 6px;
    padding:0;
  }
}