@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+HK&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Noto+Sans+HK&display=swap");
.reachGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5em;
  grid-auto-rows: minmax(100px, auto);
  padding: 50px;
}
.projectHead1Wrapper {
  display: flex;
  height: 3.5rem;
  justify-content: center;
  grid-column: 2/3; /*new*/
}
.bird1,
.bird2 {
  width: 50px;
  height: 50px;
  animation-name: Fly;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  position: relative;
}
.bird1 {
  transform: rotate(-25deg);
}
@keyframes Fly {
  0% {
    top: -10px;
    transform: scaleY(0.6);
  }
  25% {
    top: -25px;
    transform: scaleY(1);
  }
  50% {
    top: -10px;
    transform: scaleY(0.6);
  }
  75% {
    top: -25px;
    transform: scaleY(1);
  }
  100% {
    top: -10px;
    transform: scaleY(0.6);
  }
}
.projectHead1 {
  /* grid-column: 2/3; */
  width: 16rem;
  /* top: 0; New */
  text-align: center;
  height: fit-content;
  right:0;
}
.projectHead2 {
  /* grid-column: 1/2; New */
  text-align: center;
  height: fit-content;
  right:0;
}
.box {
  border: 10px outset grey;
}
.projectUdaan {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5em;
  grid-auto-rows: minmax(100px, auto);
  padding: 20px 20px 10px 20px;
  height: 92.5%; /*new*/
}
.upcomingProjects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5em;
  grid-auto-rows: minmax(100px, auto);
  padding: 20px 20px 10px 20px;
}
.box1,
.box2 {
  padding: 1em;
}
.project1 {
  border: 10px solid rgb(248, 141, 141);
  /* grid-row: 1/3; */
  grid-column: 1/3;
}
.project2 {
  border: 10px solid rgb(145, 221, 245);
  grid-column: 3/5;
}
.project3 {
  border: 10px solid rgb(160, 243, 160);
  grid-row: 2/3;
  grid-column: 1/5;
}
.header,
.p4,
.p5 {
  font-family: "Cormorant Garamond", serif;
  font-weight: bolder;
}
.p5 {
  font-size: 20px;

  cursor: pointer;
  text-align: end;
  margin: 0.5rem;
  font-size: 25px;
}
.p5 > a {
  color: green;
}
.p4 {
  /* position: absolute;
    z-index: 1; */
  position: relative;
  /* top:100px; */
  text-align: center;
  z-index: 1;
  font-size: 30px;
}
.colImg,
.colImg1 {
  display: block;
  position: relative;
  margin: auto auto;
}
.c2img1,
.c2img2,
.c2img4,
.c2img5,
.c2img7 {
  width: 100px;
  height: 100px;
}
.c2img6 {
  width: 100%;
  opacity:0.5;
  height: 150px;
}
.c1img3 {
  height: 150px;
}
.upcoming1 {
  border: 10px solid rgb(248, 141, 141);
}
.upcoming2 {
  border: 10px solid rgb(160, 243, 160);
}
.upcoming3 {
  border: 10px solid rgb(145, 221, 245);
  grid-column: 3/4;
  grid-row: 1/3;
}
.upcoming4 {
  border: 10px solid rgb(247, 247, 133);
}
.upcoming5 {
  border: 10px solid rgb(248, 141, 141);
}
.upcoming6 {
  border: 10px solid rgb(160, 243, 160);
  grid-column: 1/3;
  grid-row: 3/4;
}
.upcoming7 {
  border: 10px solid rgb(247, 247, 133);
}
.p41,
.p42 {
  top: 100px;
}
.p43 {
  top: 120px;
}
.p44,
.p45,
.p47,
.p48 {
  top: 55px;
}
.p46 {
  top: 150px;
}
.p49,
.p410 {
  top: 80px;
}
.c2img1,
.c2img2,
.c2img4,
.c2img5 {
  bottom: 20px;
}
.c2img3 {
  top: 50px;
}
.c2img6 {
  bottom: 15px;
}
@media screen and (max-width: 1150px) {
  .reachGrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .projectHead1Wrapper{
    margin-top:2rem;
  }
  .projectHead2 {
    bottom: 0;
  }
  .projectHead1{
    top:0;
  }
  .p41,
  .p42 {
    top: 85px;
  }
  .c1img1,
  .c1img2 {
    bottom: 15px;
  }
}
@media screen and (max-width: 700px) {
  .reachGrid {
    padding: 0;
    margin-bottom:2rem;
  }
  .upcomingProjects {
    /* padding-left: 0; */
    /* padding-right: 0; */
  }
  .projectUdaan {
    display: grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 92.5%; /*new*/
    /* padding-left: 2px; */
    /* padding-right: 2px; */
  }
  .upcomingProjects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .project1 {
    grid-column: 1/2;
  }
  .project2 {
    grid-column: 2/3;
  }
  .project3 {
    grid-column: 1/3;
  }
  .upcoming3 {
    grid-column: 2/3;
  }
  .upcoming6 {
    grid-column: 2/3;
  }
  .p410 {
    top: 60px;
  }
  .c2img7 {
    bottom: 20px;
  }
}
@media screen and (max-width: 455px) {
  .reachGrid {
    grid-template-columns: 100%;
    /* margin-left: 1rem; */
  }
  .projectUdaan {
    display: grid;
    display: grid;
    grid-template-columns: 100%;
    height: 92.5%; /*new*/
  }
  .upcomingProjects {
    display: grid;
    grid-template-columns: 100%;
  }
  .project1 {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .project2 {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .project3 {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .upcoming1 {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .upcoming2 {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .upcoming3 {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .upcoming4 {
    grid-column: 1/2;
    grid-row: 4/5;
  }
  .upcoming5 {
    grid-column: 1/2;
    grid-row: 5/6;
  }
  .upcoming6 {
    grid-column: 1/2;
    grid-row: 6/7;
  }
  .upcoming7 {
    grid-column: 1/2;
    grid-row: 7/8;
  }
  .c1img3 {
    width: 175px;
  }
  .p46 {
    top: 80px;
  }
  .c2img3 {
    top: -20px;
  }
  .projectHead1Wrapper {
    height: 6rem;
  }
  .projectHead2 {
    margin-top: 2rem;
  }
}
