.body{
    margin: 0;
    padding: 0;
    margin-bottom: 100px;
}

.col{
    padding: 0;
}

.imageholder{
    margin: 0;
    padding: 0;
}

.image{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.icons{
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon{
    margin: 0;
    padding: 0;
    width: 4vw;
    margin-left: 10%;
    margin-bottom: 1vw;
}

.icon:hover{
    cursor: pointer;
}

.textholder{
    margin: 0;
    padding: 1vw;
    text-align: center;
    background-image: url('https://drive.google.com/uc?id=1Z5Oha5krxfswmxqiv-jII8iOc9vZ9XxE');
}

.text{
    margin: 0;
    font-size: 2.5vw;
    font-weight: bold;
}

@media screen and (max-width: 991px) {

    .image{
        content: url('https://drive.google.com/uc?id=13rhVo5hRP2CWrsfJFxB7kKqlQtB2JV8x');
    }

    .body{
        margin-bottom: 50px;
    }

    .icons{
        padding: 10px;
        flex-direction: row;
    }
    
    .icon{
        margin: 3px;
        margin-left: 10px;
        margin-right: 10px;
        width: 40px;
    }
    
    .imageholder{
        padding: 0;
    }
    
    .textholder{
        padding: 10px;
    }
    
    .text{
        font-size: 25px;
    }
}