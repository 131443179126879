.content {
  /* background-color: white; */
  color: black;
}

.heading {
  margin-top: 10%;
  text-decoration: underline;
  text-align: center;
  font-weight: bold;
}

.para {
  text-align: center;
}

.image {
  display: block;
  margin: 0 auto;
  /* height: 150px;
  width: 150px; */
  height: 70%;
  width: 70%;
  padding: 0;
}

.card, .ban {
  background-image: url("./Images/background.png");
  background-size: 100%;
}

.theBack {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 10%;
  padding: 8%;
  font-weight: bold;
  background-color: black;
  color: white;
  box-shadow: 2px 2px 4px black;
}

.btn:hover {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: colorChange;
}

.ban {
  margin-bottom: 4%;
 
  text-align: center;
}

.rule {
  width: 10%;
  margin: 0 auto;
  border: none;
  border-top: 6px solid black;
  opacity: 1;
}
.head{
  font-weight: bolder;
  
  text-align: center;
  font-size: 40px;
  text-decoration: underline;
  padding-left: 100px;
}
.ban{
  padding: 4%;
    padding-bottom: 10px;
}
@keyframes colorChange {
  0% {
    background-color: black;
    color: white;
  }

  25% {
    background-color: white;
    color: black;
  }

  50% {
    background-color: white;
    color: black;
  }

  100% {
    background-color: black;
    color: white;
  }
}

.card {
  margin-bottom: 6%;
}

.backPara {
  text-align: center;
  padding: 1%;
  line-height: 1.5;
  font-weight: bolder;
  font-size: 90%;
}

@media screen and (max-width: 767px) {
  .card {
    margin-bottom: 10%;
  }

  .ban {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 6%;
    padding-bottom: 20px;
  }
  .head{
    padding-top: 10px;
    padding-left: 50px;
  }
  .rule {
    width: 50%;
  }
}
