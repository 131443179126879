.holder{
    background-image: url('https://drive.google.com/uc?id=1VdV7ko-IMHIneKH0b3JtBI1LLURP5ka1');
}

.content{
    padding-top: 5vh;
    padding-bottom: 5vh;

    padding-left: 10%;
    padding-right: 10%;

    margin-bottom: 100px;

    background-size: 50% 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-image: url('https://drive.google.com/uc?id=16-p33QucpgIDLvN9iOvmd9cw1_1Wnd24');
}

.heading{
    font-size: max(3.5vw,45px);
    font-weight: bold;
    color: rgb(70, 70, 70);
}

.para1{
    width: 70%;
}

.para2{
    width: 50%;
}

.para1, .para2{
    padding: 0;
    font-size: max(1.65vw,16px);
    font-style: normal;
}

@media screen and (max-width: 1300px) {
    .content{
        background-image: none;
    }

    .heading{
        font-size: 45px;
        text-align: center;
    }

    .para1, .para2{
        width: 100%;
        text-align: justify;
    }
}