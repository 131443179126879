.body{
    margin: 0;
    padding: 0;
    margin-bottom: 50px;
}

.imageholder{
    margin: 0;
    padding: 0;
    padding-left: 5vw;
    padding-right: 5vw;
}

.image{
    margin: 0;
    padding: 0;
    width: 100%;
}

.textholder{
    margin: 0;
    padding: 1vw;
    text-align: center;
    background-image: url('https://drive.google.com/uc?id=1Z5Oha5krxfswmxqiv-jII8iOc9vZ9XxE');
}

.text{
    margin: 0;
    font-size: 2.5vw;
    font-weight: bold;
}

@media screen and (max-width: 991px) {

    .body{
        margin-bottom: 50px;
    }
    
    .imageholder{
        padding: 0;
    }
    
    .textholder{
        padding: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .text{
        font-size: 25px;
    }

    .image{
        content: url('https://drive.google.com/uc?id=1Etx4rW7Ejc3J3IEZ9Tf2EzfbQXuy0vmN');
    }
}