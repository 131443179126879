.body1, .body2, .body3, .body4{
    margin: 0;
    padding: 25px;
    margin-bottom: 50px;
    background-size: 75% 100%;
    background-repeat: no-repeat;
}

.body1, .body3{
    background-position-x: right;
}

.body2, .body4{
    background-position-x: left;
}

.body1{
    background-image: url('https://drive.google.com/uc?id=1Z5Oha5krxfswmxqiv-jII8iOc9vZ9XxE');
}

.body2{
    background-image: url('https://drive.google.com/uc?id=14uLfzioqtkiN85ZaGFqwkT9PYqI8OhbN');
}

.body3{
    background-image: url('https://drive.google.com/uc?id=1eHr-2nCSKnxqQNs3-Ce1X3XqOplCA1mh');
}

.body4{
    background-image: url('https://drive.google.com/uc?id=1VdV7ko-IMHIneKH0b3JtBI1LLURP5ka1');
}

.textCol{
    margin: 0;
    padding: 2vw;
}

.heading{
    margin: 0;
    padding: 1vw;
    text-align: center;
    font-size: 3vw;
    font-weight: bold;
    color: rgb(80, 80, 80);
}

.text{
    margin: 0;
    padding: 0;
    font-size: 1.5vw;
    line-height: 1.8;
    text-align: justify;
    color: rgb(80, 80, 80);
}

.imagebox{
    margin: 0;
    padding: 0;
}

.image{
    margin: auto;
    padding: 0;
    width: 95%;
}

.smallhide{
    display: flex;
}

.bighide{
    display: none;
}

@media screen and (max-width:767px) {

    .body1, .body2, .body3, .body4{
        background-size: cover;
    }

    .heading{
        font-size: 25px;
    }
    
    .text{
        font-size: 16px;
    }

    .textCol{
        padding: 4vw;
    }

    .smallhide{
        display: none;
    }

    .bighide{
        display: flex;
        flex-direction: column;
    }
}