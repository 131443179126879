.WorkSection {
    padding-bottom: 8%;
}

.HeadText {
    color    : rgba(0, 0, 0, 0.726);
    font-size: 5vw;
}

.card_title {
    font-size  : 2vw;
    font-weight: 200;
    padding    : 1% 0 3% 0;
    color      : #796060;
}

.card {
    background-repeat: no-repeat;
    box-sizing       : border-box;
    overflow         : hidden;
    width            : 23%;
    height           : 300px;
    display          : flex row;
    flex-wrap        : nowrap;
    display          : inline-block;
    margin-left      : 10px;
    padding-top      : 2%;
}

.img_work {
    width         : 100%;
    height        : 100%;
    vertical-align: baseline;
    transition    : 0.3s;
    position      : absolute;
    object-fit    : cover;
    display       : flex row;
    flex-wrap     : nowrap;
}

.work_card {
    display   : inline-block;
    box-sizing: border-box;
    overflow  : hidden;
    width     : 100%;
    height    : 80%;
    position  : relative;
}

.img_work:hover {
    transform: scale(1.2);
}

@media only screen and (max-width: 900px) {

    .card {
        width     : 40vw;
        height    : 25vh;
        margin-top: 3%;
    }

    .card_title {
        font-size : 3vw;
        margin-top: -3%;
    }

    .HeadText {
        font-size    : 10vw;
        margin-left  : 9%;
        margin-bottom: -10%;
    }

}