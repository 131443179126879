.content {
  background-color: white;
  color           : black;
}

.card {
  border-radius: 100%;
  margin-bottom: 3%;
}

.image {
  display: block;
  margin : 0 auto;
  height : 100%;
  width  : 100%;
  padding: 0;
}

.theBack {
  height          : 100%;
  background-image: url("https://drive.google.com/uc?id=1sATPrWrtpApDsvAB_eUP6WGuA0PJmYgO");
  align-items     : center;
  justify-content : center;
  padding         : 10px;
  margin          : auto;

}

.heading {
  margin        : 10px;
  padding-top   : 0px;
  padding-bottom: 0px;
  text-align    : center;
}

.backPara {
  text-align   : center;
  margin       : 0%;
  margin-top   : auto;
  margin-bottom: auto;

}

.imge {
  height   : 460px;
  width    : auto;
  font-size: 13px;
}

.imgo {
  height   : 290px;
  width    : auto;
  font-size: 13px;
}

.text {
  vertical-align: middle;
}

/*.para {
  text-align: center;
  font-size: small;
}

.rule {
  width: 10%;
  margin: 0 auto;
  border: none;
  border-top: 6px solid black;
  opacity: 1;
}*/

@media only screen and (max-width: 1000px) {
  .imgs {
    display      : contents;
    overflow     : hidden;
    float        : left;
    height       : 100%;
    width        : 10%;
    margin-bottom: 20px;
  }

  .imge {
    display      : contents;
    overflow     : hidden;
    float        : none;
    height       : 100px;
    width        : 100%;
    margin-bottom: 20px;

  }

  .imgo {
    display : contents;
    overflow: hidden;
    float   : none;
    height  : 150px;
    width   : 100%;
    margin  : auto;

  }

  .card {
    width      : 100%;
    height     : 100%;
    display    : contents;
    overflow   : hidden;
    margin     : auto 20px auto 10px;
    align-items: center;

  }

  .image {
    display    : inline-block;
    margin-left: 20px;
    height     : auto;
    width      : 90%;
    padding    : 0;
  }

  .theBack {
    vertical-align : middle;
    height         : 100%;
    width          : 90%;
    margin         : auto 0 auto 15px;
    padding        : auto 0px;
    align-items    : center;
    justify-content: center;
    vertical-align : middle;

  }

  .heading {
    margin     : 0%;
    padding-top: 50px;
    padding    : auto 0px;

    text-align: center;

  }

  .backPara {
    padding   : auto 0px;
    text-align: center;
    margin    : 0%;
    padding   : 20px;


  }

}