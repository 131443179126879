.mainfooter {
    color: white;
    /* background-color:  #adebad; */
    padding-top: 3em;
    padding-bottom: 2rem;
    position: relative;
    bottom: 0;
    width: 100%;
    background:url("https://lh3.googleusercontent.com/d/1xZrXBdOW3OH7hXkY0LxJWIWyN76czBo4=s220?authuser=0");
    background-size: 700px;
  }
  
  .forimg{
    margin-left: 55%;
    height: 30%;
    width: 30%;
  }
  
  .verticalline {
    
    height: 100%;
    width: 5%;
    background-color: rgb(243, 195, 91);
  }
  
  .fortxt{
      text-align: center;
    margin-left: 40%;
    color: black;
    font-weight: 600;
  }

  .lastc{
      background-color: blue;
  }

  .para{
      margin-right: 50%;
      margin-left: -20%;
  }

  .par1{
    margin-left: -48%;
  }

  @media (max-width:1100px){
    .forimg{
        margin-left: 30%;
        height: 25%;
        width: 55%;
      }
      
      /* .verticalline {
        
        height: 100%;
        width: 4%;
        background-color: rgb(243, 195, 91);
      } */
      
      .fortxt{
        margin-left: 15%;
        color: black;
        font-weight: 600;
      }
    
      .para{
          margin-right: 0%;
          margin-left: -20%;
      }
    
      .par1{
        margin-left: 6%;
      }
    
  }

  @media (max-width:700px){
    .forimg{
        /* margin-left: 45%; */
        margin-right: 0%;
        height: 50%;
        width: 30%;
        margin-left: 35%;
      }
      
      .fortxt{
        
        margin-right: 14%;
        color: black;
        font-weight: 600;
      }
    
      .para{
          margin-left: 10%;
      }
    
      .par1{
        margin-left: 2%;
      }
    
  }
  