.body{
    margin: 0;
    padding: 0;
    margin-bottom: 50px;
}

.imageholder{
    margin: 0;
    padding: 0;
    padding-left: 3vw;
    padding-right: 3vw;
}

.image{
    margin: 0;
    padding: 0;
}

.textholder{
    margin: 0;
    padding: 0;
    background-image: url('https://drive.google.com/uc?id=1eHr-2nCSKnxqQNs3-Ce1X3XqOplCA1mh');
}

.text{
    margin: 0;
    padding: 2.5vw;
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
}

@media screen and (max-width: 767px) {
    
    .imageholder{
        padding: 0;
    }

    .image{
        content: url('https://drive.google.com/uc?id=1Xp3HU7lou1GzPdtuPp62pYTVjHLut89n');
    }

}