@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+HK&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Noto+Sans+HK&display=swap");

.section8{
    max-width: 100%;
    width: 100%;
    margin: 0px 0px;
    padding: 0px 80px;
}
.sec8img{
    width: 100%;
    padding: 0px 0px;
    height: 350px;
}
.collabs{
    background-image: url("https://drive.google.com/uc?id=13PQzs1s1tx85yBkYwiswLBHB7BQabT2j");
    background-attachment: inherit;
    margin-top: 10px;
    padding: 50px 80px;
    margin-bottom: 50px;
}
.sec8Col{
    margin: 0 auto;
}

.sec8Col > h1{
    font-size: 45px;
    font-family: "Cormorant Garamond", serif;
    font-weight: bolder;
}
.collabs p {
    font-size: 21px;
    font-family: "Noto Sans HK", "Source Sans Pro";
    word-spacing: 5px;
    text-align: justify;
}
.collabbutton {
    font-family: "Noto Sans HK", "Source Sans Pro";
    margin-top: 10px;
    padding: 13px 15px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    outline: none;
    background-color: #ffffa8;
    border: none;
    border-radius: 25px;
    margin-left: 5%; 
}  
.collabbutton:hover {
  background-color: #ffff8c;
  text-decoration: underline;
  color: black;
}
.mobilebutton{
    display: none;
}

/* *********************** Media Queries ************************* */
@media screen and (min-width: 1590px) {
}
@media screen and (min-width: 1450px) and (max-width: 1589px) {
}
@media screen and (min-width: 1300px) and (max-width: 1449px) {
}
@media screen and (min-width: 1260px) and (max-width: 1299px) {
    .section8{
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .collabs{
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .sec8Col > h1{
        font-size: 40px;
    }
    .collabs p {
        font-size: 19px;
    }
    .collabbutton {
        font-size: 17px;
    }  
}
@media screen and (min-width: 1126px) and (max-width: 1259px) {
    .section8{
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .collabs{
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .sec8Col > h1{
        font-size: 38px;
    }
    .collabs p {
        font-size: 18px;
    }
    .collabbutton {
        font-size: 17px;
    }  
}
@media screen and (min-width: 1000px) and (max-width: 1125px) {
    .section8{
        padding-left: 60px;
        padding-right: 60px;
    }
    .collabs{
        padding-left: 60px;
        padding-right: 60px;
    }
    .sec8Col > h1{
        font-size: 38px;
    }
    .collabs p {
        font-size: 17px;
    }
    .collabbutton {
        font-size: 16px;
    } 
}
@media screen and (min-width: 769px) and (max-width: 999px) {
    .section8{
        padding-left: 50px;
        padding-right: 50px;
    }
    .sec8img{
        height: 300px;
    }
    .collabs{
        padding-left: 50px;
        padding-right: 50px;
    }
    .sec8Col > h1{
        font-size: 36px;
    }
    .collabs p {
        font-size: 16px;
    }
    .collabbutton {
        font-size: 15px;
    } 
}
@media screen and (width: 768px) {
    .section8{
        padding-left: 40px;
        padding-right: 40px;
    }
    .sec8img{
        height: 300px;
    }
    .collabs{
        padding-left: 40px;
        padding-right: 40px;
    }
    .sec8Col > h1{
        font-size: 36px;
    }
    .collabs p {
        font-size: 16px;
    }
    .collabbutton {
        font-size: 15px;
    } 
}
@media screen and (max-width: 767px) {
    .section8{
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .sec8img{
        height: 250px;
    }
    .collabs{
        margin-left: 2rem;
        margin-right: 2rem;
        margin-top: 0px;
        padding-left: 40px;
        padding-right: 40px;
    }
    .sec8Col > h1{
        font-size: 36px;
    }
    .collabs p {
        font-size: 16px;
        word-spacing: 0px;
        text-align: left;
    }
    .collabbutton {
        font-size: 15px;
    } 
}
@media screen and (max-width: 600px) {
      /*.sec8img{
       height: 300px; 
    }*/
    .collabs{
        flex-direction: column;
    }
    .collabs p {
        text-align: justify;
    }
    .collabbutton{
        display: none;
    }
    .mobilebutton {
        display: inline;
        font-family: "Noto Sans HK", "Source Sans Pro";
        margin-top: 10px;
        padding: 13px 15px;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
        outline: none;
        background-color: #ffffa8;
        border: none;
        border-radius: 25px;
    }  
    .mobilebutton:hover {
      background-color: #ffff8c;
      text-decoration: underline;
      color: black;
    }
}
@media screen and (max-width: 575px) {
    .sec8Col > h1{
        font-size: 34px;
    }
    .sec8img{
        height: 200px;
    }
    .collabs p {
        font-size: 14px;
        text-align: justify;
    }
    .mobilebutton {
        font-size: 14px;
    } 
}
@media screen and (max-width: 444px) {
    .sec8Col > h1{
        font-size: 32px;
    }
    .collabs p {
        font-size: 14px;
        text-align: left;
    }
    .mobilebutton {
        font-size: 14px;
    }
}
@media screen and (max-width: 360px) {
    .section8{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .sec8img{
        height: 150px;
    }
    .collabs{
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .sec8Col > h1{
        font-size: 28px;
    }
    .collabs p {
        font-size: 14px;
        word-spacing: 0px;
        text-align: left;
    }
}