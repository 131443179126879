* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
 
}
body {
  background: #fefefe;
  font-family: sans-serif;
  width: 100%;
}
.bckimg{
  background-image: url(Images/Blue.png);
  background-size: 100%;
}
.containerfluid {
  height: 40% !important;
  width: 90%;
  margin: 50px auto;
}
.heading {
  font-weight: bolder;
  padding: 30px;
  text-align: center;
  font-size: 40px;
  text-decoration: underline;
  margin-bottom: 10px;
  background-image: url(Images/Blue.png);
  background-size: 100%;
  text-align: center;
  padding-left: 90px;
  padding-top: 40px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
}

.card {
  background-image: url(Images/Blue.png);
  border: none;
  width: 100%;
  border: none;
  margin-bottom: 10px;
}

.cardimgtop {
  padding: 25px;
  height: 70%;
  width: 100%;
}

.cardmain {
  width: 20%;
  border: none;
  margin-left: 10px;
  margin-bottom: 30px;
  transition: 0.3s;
  padding: 5px;
}

.cardbody {
  padding: 1px 1px;
  text-align: center;
  font-size: 18px;
}

.cardmain:hover {
  transform: scale(1.05);
  box-shadow: 0 0 50px -10px rgba(0, 0, 0, 0.55);
}

@media screen and (max-width: 1000px) {
  .cardmain {
    width: 40%;
  }
  .heading1{
    display: inline;
  }
}

@media screen and (max-width: 620px) {
  .containerfluid {
    width: 100%;
  }

  .heading {
    padding: 20px;
    font-size: 40px;
    /* background-size: 100%; */
    padding-left: 50px;
    padding-top: 30px;
  }

  .cardmain {
    width: 80%;
  }
}
