.icon{
    margin: 0;
    height: max(5vw,50px);
}

.holder{
    margin-bottom: 100px;
    
    text-align: center;
    background-size: cover;
    background-image: url('https://drive.google.com/uc?id=14uLfzioqtkiN85ZaGFqwkT9PYqI8OhbN');
}

.text{
    margin: 0;
    padding: 4vh;
    font-size: max(2vw, 30px);
    font-weight: bolder;
    justify-content: center;
    color: rgb(100, 100, 100);
}

.iconlist{
    padding-left: 50px;
    padding-right: 50px;
}

.caption{
    color: black;
    font-size: max(1.2vw, 15px);
    font-weight: bold;
}

.counter{
    color: black;
    display: block;
    font-size: max(2vw, 30px);
}

.progressbar .progress {
    height: 100%;
    border-radius: 4px;
    background-color: black;
    transition: width 4s ease-in-out;
}
  
.progressbar {
    width: 78%;
    height: 30px;
    padding: 10px;
    border-radius: 4px;
    margin-left: 11%;
    margin-right: 11%;
    margin-bottom: 1vh;
    background-color: lightgrey;
}

.years{
    padding-bottom: 3vh;
}

.year{
    color: black;
    font-size: max(1.6vw,20px);
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .caption{
        margin-bottom: 25px;
    }
    .progressbar {
        height: 20px;
        padding: 5px;
    }
}