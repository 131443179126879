@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+HK&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Noto+Sans+HK&display=swap");

.rowOneColOne > img {
  width: 600px;
  height: 350px;
  position: relative;
  left: 5rem;
}

.rowOneColTwo {
  /* background-image: url("./AboutUsImages/backRed.png"); */
  background-image: url("https://drive.google.com/uc?id=1JXc-HcF6gmJriC3-426A-jON98heQf6W");
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.rowOneColTwo > div {
  margin-left: 18rem;
  padding-top: 2rem;
}

.rowOneColTwo h1 {
  right:0;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

@media screen and (min-width: 999px) {
  @keyframes change {
    0% {
      letter-spacing: 0px;
    }

    25% {
      letter-spacing: 2px;
    }

    50% {
      letter-spacing: 4px;
    }

    75% {
      letter-spacing: 2px;
    }

    100% {
      letter-spacing: 0px;
    }
  }
}

.rowTwoDiv {
  background-image: url("https://drive.google.com/uc?id=1C0_ElA3Vqe2lN7cRTwvjJdSKyQ02gZ8k");
  background-attachment: inherit;
  background-size: cover;
  background-position: left;
  height: 6rem;
  width: 100%;
}

.rowTwoDiv > h1 {
  font-family: "Cormorant Garamond", serif;
  font-weight: bolder;
  padding-top: 10px;
  padding-left: 80px;
  font-size: 45px;
}

.rowOneColTwo p {
  color: grey;
  font-weight: bolder;
  font-style: oblique;
  font-family: "Source Sans Pro";
  word-spacing: 3px;
}

.rowTwoRowPara {
  font-family: "Noto Sans HK", "Source Sans Pro";
  color: black;
  word-spacing: 5px;
  font-size: 21px;
}

.rowTwoRowPara > p {
  padding-left: 80px;
  padding-right: 80px;
  text-align: justify;
}
@media screen and (min-width: 1590px) {
}
@media screen and (min-width: 1450px) and (max-width: 1589px) {
}
@media screen and (min-width: 1300px) and (max-width: 1449px) {
}
@media screen and (min-width: 1260px) and (max-width: 1299px) {
  .rowOneColOne > img {
    height: 300px;
    left: 3rem;
  }

  .rowOneColTwo > div {
    padding-top: 1rem;
  }

  .rowTwoDiv > h1 {
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 40px;
  }

  .rowTwoRowPara > p {
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 19px;
  }
}
@media screen and (min-width: 1126px) and (max-width: 1259px) {
  .rowOneColOne > img {
    width: 550px;
    height: 300px;
    left: 4rem;
  }

  .rowOneColTwo > div {
    padding-top: 1rem;
  }

  .rowTwoDiv > h1 {
    padding-left: 4rem;
    padding-right: 4rem;
    font-size: 38px;
    padding-top: 20px;
  }

  .rowTwoRowPara > p {
    padding-left: 4rem;
    padding-right: 4rem;
    font-size: 18px;
  }
}
@media screen and (min-width: 1051px) and (max-width: 1125px) {
}
@media screen and (min-width: 1000px) and (max-width: 1050px) {
}

@media screen and (min-width: 1000px) and (max-width: 1125px) {
  .rowOneColOne > img {
    width: 500px;
    height: 300px;
    left: 8px;
    padding-left: 60px;
  }

  .rowOneColTwo > div {
    padding-top: 1rem;
  }

  .rowTwoDiv > h1 {
    padding-left: 60px;
    padding-top: 20px;
    font-size: 38px;
  }

  .rowTwoRowPara > p {
    padding-left: 60px;
    padding-right: 60px;
    font-size: 17px;
  }
}
@media screen and (min-width: 870px) and (max-width: 999px) {
}
@media screen and (min-width: 769px) and (max-width: 999px) {
  .rowOneColOne > img {
    width: 450px;
    height: 350px;
    left: 16px;
    padding-left: 50px;
  }
  .rowOneColTwo > div {
    margin-left: 16rem;
  }
  .rowTwoDiv > h1 {
    padding-top: 20px;
    padding-left: 50px;
    right: 0;
    font-size: 36px;
  }

  .rowTwoRowPara > p {
    padding: 0px 50px 0px 50px;
    font-size: 16px;
  }
}

@media screen and (width: 768px) {
  .rowOneColOne > img {
    width: 450px;
    left: 16px;
    padding-left: 30px;
    margin-left: 0px;
  }

  .rowOneColTwo > div {
    padding-top: 0;
  }

  .rowTwoDiv > h1 {
    padding-top: 20px;
    padding-left: 40px;
    right: 0;
    font-size: 36px;
  }

  .rowTwoRowPara > p {
    padding-left: 40px;
    padding-right: 40px;
    font-size: 16px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .section1,
  .section2 {
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0px 0px;
  }

  .rowTwoRowPara {
    background-color: cornsilk;
  }

  .rowOneColOne > img {
    width: 100%;
    height: 100%;
    left: 0px;
  }

  .rowOneColTwo > div {
    margin-left: 0rem;
    max-width: 100%;
  }

  .rowTwoDiv > h1 {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    right: 0;
    font-size: 34px;
  }
   .rowTwoRowPara{
    margin-left: 1px;
   }
  .rowTwoRowPara > p {
    padding-left: 10px;
    padding-right: 10px;
    /* font-size: 14px; */
    font-size:16px;
  }
}
@media screen and (min-width: 700px) and (max-width: 767px) {
}
@media screen and (min-width: 640px) and (max-width: 700px) {
}
@media screen and (max-width: 575px) {
  .section1,
  .section2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .rowOneColOne > img {
    width: 100%;
    height: 100%;
    left: 0px;
  }

  .rowOneColTwo > div {
    margin-left: 0rem;
    padding-top: 0;
  }

  .rowTwoDiv > h1 {
    padding-top: 20px;
    padding-left: 8px;
    right: 0;
    font-size: 34px;
  }

  .rowTwoRowPara {
    background-color: cornsilk;
    word-spacing: 0;
    margin-left: 1px;
  }

  .rowTwoRowPara > p {
    padding: 8px 8px;
    font-size:16px;
  }
}
@media screen and (max-width: 444px) {
  .rowOneColOne img {
    height: 120%;
  }

  .rowOneColTwo > div {
    padding-top: 2rem;
  }

  .rowTwoRowPara > h1 {
    font-size: 32px;
  }

  .rowTwoRowPara > p {
    text-align: left;
  }
}
@media screen and (max-width: 360px) {
  .section1,
  .section2 {
    margin: 0 1rem;
  }

  .rowOneColOne img {
    height: 100%;
  }

  .rowOneColTwo > div {
    padding-top: 1rem;
  }
   .rowOneColTwo >div >h1{
     font-size:x-large;
   }
  .rowTwoRowPara > h1 {
    text-align: justify;
    font-size: xx-large;
  }

}
@media screen and (max-width:265px){
  .rowTwoDiv>h1{
    text-align: center;
    padding-left: 0;
    padding-top: 10%;
  }
}