.allover {
  height       : 40%;
  width        : 80%;
  /*margin-left: 8%;*/
}

.alloverr {
  margin-left: 17%;
}

.imagess {
  height         : 90%;
  width          : 100%;
  padding        : 5%;
  justify-content: center;
  align-items    : center;
  display        : flex;
}

.h {
  margin-top : 55px;
  margin-left: 55px;
  font-size  : 40px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}

.pink {
  background: url("https://drive.google.com/uc?id=1tKJz1WQDY4CdXtPVnsIj4quqEkHMAjSL");
}

.green {
  background: url("https://drive.google.com/uc?id=1f3ljJgoCfyHuDEKG6m_-D3_Yg4Jwbn04");
}

.orange {
  background: url("https://drive.google.com/uc?id=11ZKzHqt_GPZeXzHr50uHs9Rt39wSPh5A");
}

.blue {
  background: url("https://drive.google.com/uc?id=1sATPrWrtpApDsvAB_eUP6WGuA0PJmYgO");
}

.contenp {
  width             : 100%;
  height            : 100%;
  font-size         : 18px;
  text-align        : justify;
  display           : flex;
  align-items       : center;
  padding-left      : 15%;
  /* justify-content: center; */
}


@media screen and (min-width: 768px) {
  .TabDropdown {
    display: none;
  }
}

@media(max-width: 600px) {
  .contenp {
    width       : 100%;
    height      : 100%;
    font-size   : 15px;
    text-align  : justify;
    display     : flex;
    align-items : center;
    padding-left: 3%;
  }

  .h {
    font-size  : 7vw;
    margin-left: 21%;
    margin-top : -15%;
    text-align : center;
    width      : 100%;
  }

  .TabDropdown {
    border       : solid 1px;
    margin-left  : 12%;
    height       : 5vh;
    font-size    : 110%;
    padding      : 2%;
    margin-bottom: 5%;
    font-weight  : bold;
  }

  .alloverr {
    display: block;
    width  : 100%;
  }

  .Tabs {
    display: none;
  }

  .tab {}
}