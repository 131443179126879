.body{
    margin: 0;
    padding: 0;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 50px;
  }
  
  .header{
    font-size: 1.4vw;
  }
  
  .holder{
    margin: 0;
    padding: 1.5vw;
    background-image: url('https://drive.google.com/uc?id=14uLfzioqtkiN85ZaGFqwkT9PYqI8OhbN');
  }
  
  .input{
    margin: 0;
    padding: 0;
    width: 60%;
    padding: 8px;
    font-size: 1.4vw;
    margin-bottom: 1.5vw;
  }
  
  .textbox{
    margin: 0;
    padding: 8px;
    width: 100%;
    height: 12vw;
    font-size: 1.4vw;
    margin-bottom: 1.5vw;
  }
  
 
.submit{
  margin: 0;
  padding: 0;
  float: left;
  padding: 0.4vw;
  font-weight: bold;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  border-radius: 2.5vw;
  border-style: hidden;
  background-color: white;
}
.ContactUsSpace
{
padding:20px;

}
  
  .submit:hover{
    border-width: 2px;
    border-style: solid;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  
  @media screen and (max-width: 991px) {
    
    .header{
      font-size: 20px;
    }
    
    .input{
      width: 100%;
      padding: 15px;
      font-size: 18px;
      margin-bottom: 15px;
    }
    
    .textbox{
      height: 200px;
      padding: 15px;
      font-size: 18px;
      padding-top: 10px;
      margin-bottom: 15px;
    }
  
    .submit{
      font-size: 18px;
      border-radius: 15px;
      margin-bottom: 15px;
    }
    .ContactUsSpace
    {
    padding:10px;
    
    }
  }