.Section {
    margin-bottom: 10%;
}

.HeadText {
    font-family: "Open Sans", sans-serif;
    margin-left: 55px;
    font-size  : 40px;
    font-weight: 700;
}

.tileBox {
    display        : flex;
    flex-direction : row;
    flex-wrap      : wrap;
    justify-content: center;
    margin-top     : 50px;
}

.tile {
    padding          : 25px;
    font-size        : 2vw;
    font-weight      : 600;
    margin           : 2vw;
    width            : 200px;
    height           : auto;
    text-align       : center;
    background-repeat: no-repeat;
    background-size  : cover;
    box-shadow       : rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    transition       : transform 0.2s ease-in-out;
}

.tile:hover {
    transform : scale(1.3);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor    : pointer;
}

@media only screen and (max-width: 900px) {
    .HeadText {
        font-size : 8vw;
        margin-top: 5%;
        text-align: center;
    }

    .HeadLine {
        width     : 48%;
        margin-top: -3vh;
        float     : right;
        border-top: 4px solid #0a0a0a;
    }

    .tile {
        padding-top   : 2vw;
        padding-left  : 3vw;
        padding-right : 3vw;
        padding-bottom: 3vw;
        font-size     : 3vw;
        width         : 23vw;
    }

    .tileBox {
        margin-top: 2%;
    }
}

@media only screen and (min-width:1400px) {
    .tile {
        font-size: 28px;
    }
}