@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+HK&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Noto+Sans+HK&display=swap");

/* ************ Section 5 *************/
.section5 {
  position: relative;
}
.sec5Row1{
    max-width: 100%;
    width: 100%;
    margin: 0px 0px;
    padding: 0 80px;
}
.sec5Img{
    max-width: 100%;
    width: 100%;
    height: 450px;
    padding: 0px 0px;
    transition: all 500ms ease-in-out;
    z-index: 1;  
}
.sec5Img:hover{
    transform: scale(1);
    z-index: 3;
}

.sec5Content{
    position: relative;
    background-image: url("https://drive.google.com/uc?id=1JXc-HcF6gmJriC3-426A-jON98heQf6W");
    padding: 30px 80px;
    margin-top: -70px;
    margin-bottom: 30px;
    z-index: 2;
}
.sec5para{
    text-align: justify;
    font-size: 21px;
    font-family: "Noto Sans HK", "Source Sans Pro";
    word-spacing: 5px;
    z-index: 1;
}

/* ************ Section 6 *************/
.sec6Col1, .sec6Col2, .sec6Col3{
    max-width: 100%;
}
.sec6Img{
    max-width: 100%;
    height: 400px; 
    width: 100%;
    transition: 300ms ease-in-out;
    z-index: 1;
}
.sec6Img:hover {
    transform: scale(1.1);
    z-index: 3;
}

.sec6Wrap{
    background-image: url("https://drive.google.com/uc?id=1JXc-HcF6gmJriC3-426A-jON98heQf6W");
    background-attachment: inherit;
    width: fit-content;
    padding: 10px 20px;
    margin: -15px auto 0px auto;
    transform: translate(0%, -30%);
    z-index: 2;
}
.sec6Wrap > h4{
    font-family: "Noto Sans HK", "Source Sans Pro";
    word-spacing: 5px;
    font-size: 21px;
    font-weight: inherit;
    z-index: 2; 
    text-align: center;
} 

.section6b{
  display: none;
}
/* ******************* Media Queries ********************* */
@media screen and (min-width: 1590px) {
}
@media screen and (min-width: 1450px) and (max-width: 1589px) {
}
@media screen and (min-width: 1300px) and (max-width: 1449px) {
} 
@media screen and (min-width: 1260px) and (max-width: 1299px) {
  .sec5Row1{
      padding: 0 3rem;
  }
  .sec5Content{
      padding-left: 3rem;
      padding-right: 3rem;
  }
  .sec5para{
      font-size: 19px;
  }
  .sec6Wrap > h4{
    font-size: 19px;
  } 
}
@media screen and (min-width: 1126px) and (max-width: 1259px) {
  .sec5Row1{
    padding: 0 4rem;
  }
  .sec5Content{
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .sec5para{
    font-size: 18px;
  }
  .sec6Img{
    height: 350px; 
  }
  .sec6Wrap > h4{
    font-size: 18px;
  } 
}
@media screen and (min-width: 1000px) and (max-width: 1125px) {
  .sec5Row1{
    padding: 0 60px;
  }
  .sec5Content{
    padding-left: 60px;
    padding-right: 60px;
  }
  .sec5para{
    font-size: 17px;
  }
  .sec6Img{
    height: 330px; 
  }
  .sec6Wrap > h4{
    font-size: 17px;
  } 
}
@media screen and (min-width: 769px) and (max-width: 999px) {
  .sec5Row1{
    padding: 0 50px;
  }
  .sec5Content{
    padding-left: 50px;
    padding-right: 50px;
  }
  .sec5para{
    font-size: 16px;
  }
  .sec6Img{
    height: 310px; 
  }
  .sec6Wrap > h4{
    font-size: 16px;
  } 
}
@media screen and (width: 768px) {
  .sec5Row1{
    padding: 0 40px;
  }
  .sec5Content{
    padding-left: 40px;
    padding-right: 40px;
  }
  .sec5para{
    font-size: 16px;
  }
  .sec6Img{
    height: 310px; 
  }
  .sec6Wrap > h4{
    font-size: 16px;
  } 
}
@media screen and (max-width: 767px) {
  .sec5Row1{
    padding: 0 2rem;
  }
  .sec5Content{
    margin: 0 2rem 30px 2rem;
    padding: 20px 20px;
  }
  .sec5para{
    font-size: 16px;
  }
  .sec5Img{
    height: 350px;
  }
  .section6{
    display: none;
  }
  /****** Section 6b *********/
   .section6b{
    display:block;
    margin: 0px 0px;
  }
  .sec6Carousel img {
    max-width: 100%;
    padding: 0 2rem;
  }
  .sec6bWrap{
    background-image: url("https://drive.google.com/uc?id=1JXc-HcF6gmJriC3-426A-jON98heQf6W");
    background-attachment: inherit;
    width: fit-content;
    padding: 10px 20px;
    margin: 0px auto 0px auto;
  }
  .sec6bWrap > h4{
    font-family: "Noto Sans HK", "Source Sans Pro";
    word-spacing: 5px;
    font-size: 16px;
    font-weight: inherit;
  }  
}
@media screen and (max-width: 767px) and (min-width: 601px) {
  .sec6Carousel img {
    height: 400px;
  }
}
@media screen and (max-width: 600px) {
  .sec5para{
    /* font-size: 15px; */
    font-size:16px;
    word-spacing: 2px;
  }
  .sec5Img{
    height: 250px;
  }
  .section6{
    display: none;
  }
}
@media screen and (max-width: 575px) {
  /* .sec5para{
    font-size: 14px;
  } */
  .section6{
    display: none;
  }
  .sec5Img{
    height: 200px;
  }
}
@media screen and (max-width: 444px) {
  .sec5Img{
    height: 250px;
  }
  .sec5Content{
    padding: 10px 10px;
  }
  .section6{
    display: none;
  }
 .sec5para{
   text-align:left;
 }
}
@media screen and (max-width: 360px) {
  .sec5Row1{
    padding: 0 1rem;
  }
  .sec5Content{
    margin: 0 1rem 30px 1rem;
    padding: 10px 10px;
  }
  /* .sec5para{
    font-size: 14px;
  } */
  .sec5Img{
    height: 250px;
  }
  .section6{
    display: none;
  }
  .sec6bWrap{
     padding:2px;
     width:65%;
  }
  .sec6bWrap h4{
    font-size: 14px;
  }
  .sec5Img{
    height: 150px;
  }
}
