* {
  box-sizing: border-box;
}

.body {
  margin-bottom: 3%;
  padding-bottom: 0%;
}

.leftSec {
  height: 70vh;
}

.rightSec {
  height: 70vh;
  background-image: url("/src/Components/Internship/Joinus/images/Background11.png");
}

.wrapForm {
  margin-left: 29%;
}

.imgWrap {
  width: 600px;
  z-index: 20;
  margin-left: 10%;
  transform: translate(20px, 20px);
}

.formRow {
  margin-top: -2%;
}

.picture {
  border-top-left-radius: 5.6rem;
  border-bottom-right-radius: 5.6rem;

  object-fit: cover;
  z-index: 20;
  width: 100%;
}

.heading {
  color: rgb(77, 75, 75);
  position: relative;
  font-size: 3vw;
}
.info {
  color: rgb(77, 75, 75);
  position: relative;
  font-weight: 500;
  font-size: 2vw;
  margin-top: -1.5%;
}
form {
  color: black;
  position: relative;
  font-weight: bold;
  font-size: 1.7vw;
  margin-top: 4%;
}
.input {
  width: 25vw;
  height: 32px;
  position: relative;
  border: none;
  border-radius: 2rem;
  background-color: rgb(221, 219, 219);
}

.btn {
  height: 40px;
  width: 12vw;
  color: white;
  background-color: rgb(97, 95, 95);
  border: none;
  border-radius: 2rem;
  position: relative;
  padding: 0.3% 5%;
  cursor: pointer;
  font-size: 1.5vw;
  margin-left: 43%;
}
.ContactUsSpace
{
padding:10px;

}

.submit{
  margin: 0;
  padding: 0;
  float: left;
  padding: 0.4vw;
  font-weight: bold;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  border-radius: 2.5vw;
  border-style: hidden;
  background-color: white;
}

@media only screen and (max-width: 1050px) {
  .leftSec {
    order: 1;
  }

  .rightSec {
    order: 2;
  }

  .imgWrap {
    height: 100%px;
    width: 140%;
    z-index: 10;
    margin-left: 10%;
    padding-top: 5%;
  }

  input {
    width: 30vw;
    height: 32px;
  }

  .formRow {
    margin-top: 2%;
  }
}

@media only screen and (max-width: 760px) {
  .leftSec {
    order: 1;

    height: 40vw;
  }

  .rightSec {
    order: 2;
    height: 40%;
  }

  .imgWrap {
    width: 87%;
    z-index: 10;
    margin-top: -3%; 
    margin-left: 7%; 
    padding: 0%;
  }

  .picture {
    width: 90%;
  }

  .wrapForm {
    margin-left: 10%;

    padding-top: 10%;
  }

  .heading {
    font-size: 7vw;
  }

  .info {
    font-size: 3.5vw;
    margin-top: -1.5%;
  }

  form {
    font-size: 4vw;
    margin-top: -1%;
  }

  .formRow {
    margin-top: -1%;
  }

  input {
    width: 60vw;
    height: 30px;
    margin-top: -5%;
  }

  .btn {
    height: 35px;
    width: 30vw;
    padding: 0.3% 5%;
    font-size: 4vw;
    margin-left: 18%;
    margin-top: -5%;
  }
}@media screen and (max-width: 770px) and (min-width: 765px) {
  .leftSec {
    order: 1;

    height: 40vw;
  }
  .rightSec {
    order: 2;
    height: 30vh;
  }
  input {
    width: 25vw; 
  }
  .btn {
    height: 3vh; 
  }
}

@media screen and (max-width: 1025px) and (min-width: 1020px) {
  .leftSec {
    order: 1;

    height: 40vw;
  }
  .rightSec {
    order: 2;
    height: 30vh;
  }
  input {
    width: 25vw; 
  }
}

@media screen and (max-width: 767px) {
  .wrapForm {
    margin-left: 10%;
    margin-right: 10%;
  }
  .input {
    width: 100%;
  }
  .btn{
    float: right;
  }
}