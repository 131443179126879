.content{
  
  margin-bottom: 100px;

  padding-top: 7vh;
  padding-bottom: 11vh;

  justify-content: center;
  text-align-last: center;

  background-size: cover;
  background-image: url('https://drive.google.com/uc?id=1Z5Oha5krxfswmxqiv-jII8iOc9vZ9XxE');
}

.heading{
  
  margin: 0;
  
  padding: 10%;
  padding-top: 3vh;
  padding-bottom: 3vh;
  
  text-align: center;
  
  font-weight: bold;
  font-size: max(2vw, 25px);
}

.text{
  
  margin: 0;
  padding: 0;
  
  padding-left: 10%;
  padding-right: 10%;
  
  line-height: 2;
  text-align: justify;
  font-size: max(1.6vw,16px);
}

.logo{

  margin: 0;
  padding: 0;
  
  width: max(6vw, 80px);
  height: max(6vw, 80px);
}